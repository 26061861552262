<template>
    <router-link :to="'/channel/' + channel.id" class="channel" v-ripple :style="{'background-color': `${channel.color}` }" :class='size'>
        <img :src="channel.imgs[0]"/>
          <p> {{channel.name.replaceAll(' ', '')}} </p>
    </router-link>
</template>

<script>

export default {
  name: 'channel',
  props: {
    channel: Object,
    size: {
        type: String,
        default: function(){ return ''}
    }
  },

  computed: { },

  data: function(){
      return {}
  },

  methods: {

  },

  mounted: function(){
  }
}
</script>

<style scoped lang="scss">
.channel{
    position: relative;
    border-radius: 5px;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
    height: 50px;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    font-size: 9px;
    text-transform: uppercase;
    padding-right: 20px;
    align-items: center;
    p{
        display: block;
        padding-left: 10px;
        font-weight: bold;
    }
    img{
        width: 50px;
        height: auto;
        padding: 10px;
        object-fit: fit;
    }
}
</style>
